import { Routes } from '@angular/router';

export const routes: Routes = [
  {
    path: 'users',
    loadComponent: () =>
      import('./features/users-list/users-list-container/components/users-list-container/users-list-container.component')
        .then(m => m.UsersListContainerComponent),
    children: [
      {
        path: 'borrowers',
        loadComponent: () =>
          import('./features/users-list/borrowers-list/borrowers-list-container/borrowers-list-container.component')
            .then(m => m.BorrowersListContainerComponent),
      },
      {
        path: 'verification-requests',
        loadComponent: () =>
          import('./features/users-list/verification-requests-list/verification-requests-list-container/verification-requests-list-container.component')
            .then(m => m.VerificationRequestsListContainerComponent),
      },
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'verification-requests'
      }
    ]
  },
  {
    path: 'users/verification-requests/:id',
    loadComponent: () =>
      import('./features/verification-request/verification-request-item-container/verification-request-item-container.component')
        .then(m => m.VerificationRequestItemContainerComponent),
  },
  {
    path: 'users/borrowers/:id',
    loadComponent: () =>
      import('./features/borrower/borrower-container/borrower-container.component')
        .then(m => m.BorrowerContainerComponent),
    children: [
      {
        path: 'details',
        loadComponent: () =>
          import('./features/borrower/borrower-details-container/borrower-details-container.component')
            .then(m => m.BorrowerDetailsContainerComponent),
      },
      {
        path: 'active-campaigns',
        loadComponent: () =>
          import('./features/borrower/borrower-active-campaigns-container/borrower-active-campaigns-container.component')
            .then(m => m.BorrowerActiveCampaignsContainerComponent),
      },
      {
        path: 'archived-campaigns',
        loadComponent: () =>
          import('./features/borrower/borrower-archived-campaigns-container/borrower-archived-campaigns-container.component')
            .then(m => m.BorrowerArchivedCampaignsContainerComponent),
      },
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'details'
      }
    ]
  },
  {
    path: 'campaigns/:id',
    loadComponent: () =>
      import('./features/campaign/components/campaign-item-container/campaign-item-container.component')
        .then(m => m.CampaignItemContainerComponent),
  },
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'users'
  }
];
