import { APP_INITIALIZER, ApplicationConfig, provideZoneChangeDetection } from '@angular/core';
import { provideRouter, withComponentInputBinding } from '@angular/router';
import { routes } from './app.routes';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { authInterceptor, LoginResponse, OidcSecurityService, provideAuth } from 'angular-auth-oidc-client';
import { authCodeFlowConfig } from './auth.config';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { firstValueFrom, tap } from 'rxjs';

export const appConfig: ApplicationConfig = {
  providers: [
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(routes, withComponentInputBinding()),
    provideHttpClient(withInterceptors([authInterceptor()])),
    provideAuth({
      config: authCodeFlowConfig
    }),
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      multi: true,
      deps: [OidcSecurityService],
    },
    provideAnimationsAsync(),
  ]
};

export function initializeApp(oidcSecurityService: OidcSecurityService) {
  return (): Promise<LoginResponse> =>
    firstValueFrom(
      oidcSecurityService.checkAuth().pipe(tap(val => {
        if (!val.isAuthenticated) {
          oidcSecurityService.authorize();
        }
      }))
    );
}
