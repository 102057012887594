import { OpenIdConfiguration } from "angular-auth-oidc-client";
import { environment } from "../environments/environment";


export const authCodeFlowConfig: OpenIdConfiguration = {
  authority: environment.authUrl,
  redirectUrl: window.location.origin,
  clientId: 'Cosign4MeAdmin',
  responseType: 'code',
  scope: 'openid profile offline_access cosign_admin_api_access',
  secureRoutes: [environment.apiUrl],
  silentRenew: true,
  useRefreshToken: true,
  postLoginRoute: '/',
  unauthorizedRoute: '/',
};